<template>
    <!-- Matches -->
    <div class="">
    <div class="d-flex justify-content-between">
        <div class="h2">Tracked Matches</div>
        <div><button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#createMatchModal">Create new match</button></div>
    </div>

    <!-- Matches table -->
    <div style="margin-top: -5px">
        <div v-if="fetchingMatches"><img class="loader-lg" src="@/assets/loader.gif"/></div>
        <div v-else-if="errorFetchingMatches">Error loading matches: {{errorFetchingMatches}}</div>
        <MatchesTable v-else :data="matches"></MatchesTable>
        <!-- <ul v-else>
        <li v-for="matchId in matches" v-bind:key="matchId">{{matchId}}</li>
        </ul> -->
    </div>

    <!-- Match create modal -->
    <div class="modal fade" ref="createMatchModal" tabindex="-1" id="createMatchModal" aria-labelledby="createMatchModalLabel" aria-hidden="true"  data-backdrop="false">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title">Create a new Match</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div class="row">
                <div class="form-group col">
                <label for="inputEmail4">Opponent:</label>
                <select-athlete v-model="matchData.opponent" />
                </div>
            </div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-success" @click="submitMatch" :disabled="creatingMatch">Create Match <img v-if="creatingMatch" class="loader" src="@/assets/loader.gif"/></button>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>

<script>
import MatchesTable from "@/components/MatchesTable";
import { getMatches, createMatch } from "@/api";
import SelectAthlete from '@/components/SelectAthlete.vue';
import { mapGetters } from "vuex";

export default {
    mounted(){ 
      this.fetchMatches()
    },
    
    data() {
        return {
          matches: {},
          fetchingMatches: false,
          errorFetchingMatches: false,
          creatingMatch: false,
          matchData: {
            opponent: null
          },
        }
    },
    components: {
        MatchesTable, SelectAthlete
    },
    computed: {
      ...mapGetters(['selectedAthlete']),
    },
    methods: {
        async fetchMatches() {
        this.fetchingMatches = true;
        try { 
            let matchesResponse = await getMatches({ athleteId: this.selectedAthlete.id });
            console.log("Got matches");
            for (let matchId of Object.keys(matchesResponse.data.matches)) {
                this.matches[matchId] = matchesResponse.data.matches[matchId];
                this.$store.commit("UPDATE_MATCH", this.matches[matchId]);  

            }
        } catch(e) {
            this.errorFetchingMatches = e.message;
        } finally {
            this.fetchingMatches = false;
        }
        },
        async submitMatch() {
        this.creatingMatch = true;
        try {
            await createMatch({
            athletes: {
                [this.selectedAthlete.id]: true,
                [this.matchData.opponent.id]: true
            }
            });

            await this.fetchMatches();
            
            console.log("Tried to hide");
            // Redirect to the match?
        } catch (e) {
            this.Swal.fire({ title: "Uh-o", "text": e.message, icon: 'error' });
        } finally {
            this.creatingMatch = false;
        }
        },

    }
}
</script>