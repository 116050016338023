<template>
    <multiselect
        v-on:input="updateValue($event)"
        v-bind:value="value"
        
        :options="athletes"
        :searchable="false"
        :close-on-select="false"
        :show-labels="false"
        label="lastName"
        track-by="id"
        placeholder="Select an athlete">
            <template slot="singleLabel" slot-scope="props">
                <img class="option__image" :src="props.option.avatar" alt="">
                <span class="option__desc">
                    <span class="option__title ms-3">{{ props.option.lastName }}, {{ props.option.firstName }}</span><span><country-flag :country='props.option.country' :rounded='true'/></span>
                </span>
            </template>
            <template slot="option" slot-scope="props">
                <img class="option__image" :src="props.option.avatar" alt="">
                <span class="option__desc">
                    <span class="option__title ms-3">{{ props.option.lastName }}, {{ props.option.firstName }}</span><span><country-flag :country='props.option.country' :rounded='true'/></span>
                </span>
            </template>
        
        </multiselect>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        value: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            // selectedAthlete: this.value,


        }
    },
    computed: {
        ...mapGetters(['athletes']),
        // multiAthletes() {
        //     return this.athletes.map(athlete => ({
        //         title: `${athlete.lastName}, ${athlete.firstName}`,
        //         img: ""
        //         country
        //     }));
        // }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style lang="scss">
.multiselect__content-wrapper {
position: static;
}

</style>