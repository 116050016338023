<template>
    <div>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Match ID</th>
                    <th scope="col">Location</th>
                    <th scope="col">DateTime</th>
                    <th scope="col">Opponent ID(s)</th>
                    <th scope="col">Number of Rounds</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="match in matches" :key="match.id"  @click="gotoMatch(match.id)" style="cursor:pointer;">
                    <th scope="row">{{match.id}}</th>
                    <td>{{match.location || "N/A" }}</td>
                    <td>{{match.date || "N/A" }}</td>
                    <td>{{Object.keys(match.athletes).filter(x => x != selectedAthlete.id).join(',')}}</td>
                    <td>{{ Object.keys(match.rounds || {}).length }}</td>
                    <td>
                        <div @click.stop="deleteMatch(match.id)" class="btn btn-danger btn-xs" size="sm" variant="danger">Delete</div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="matches.length == 0" style="margin-top: -15px; margin-left: 0px; margin-bottom: 10px;">No matches</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            deletedIds: []
        };
    },
    props: {
        'data': {
            type: Object,
        }
    },
    computed: {
        ...mapGetters(['selectedAthlete']),
        matches() {
            return Object.values(this.data).filter(x => this.deletedIds.indexOf(x.id) == -1);
        }
    },
    methods: {
        gotoMatch(matchId) {
            this.$router.push({ 
                name: "Match",
                params: {
                    id: matchId
                }
            })
        },
        async deleteMatch(matchId) {
            let { dismiss } = await this.Swal.fire({
                title: "Are you sure?",
                text: "This will permanently delete the resourec",
                showCancelButton: true,
                showConfirmButton: true,
            });
            if (dismiss) return;

            // Delete
            this.Swal.showLoading();
            await this.$store.dispatch("deleteMatch", matchId);
            this.deletedIds.push(matchId)
            
            this.Swal.close()
        }
    }
}
</script>

<style scoped>
.table > :not(caption) > * > * { 
    padding: 0;
}

</style>